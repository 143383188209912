@import './fonts.scss';

// **************************** variables ************************** START
$componentFontRegular: $openSansRegular;
$componentFontBold: $openSansBold;
$componentWidth: 280px;
$componentHeight: 60px;
$captionFS: 14px;
$valueFS: 16px;
$green-1: rgb(21, 128, 61);
$green-2: rgb(34, 197, 94);
$red-1: rgb(170, 21, 29);
$red-2: rgb(230, 21, 29);
$red-3: rgb(252, 165, 165);
$orange-1: rgb(250, 204, 21);
$orange-2: #faff58;
$blue-1: #3961d2;
$blue-2: #4c8dd6;
$purple-1: #8429be;
$purple-2: #b764eb;
$grey-1: rgb(55, 65, 81);
$grey-2: rgb(75, 85, 99);
$grey-3: #0003;
$gradient-grey-1: linear-gradient(to bottom right, transparent, #1f2937);
$gradient-grey-2: linear-gradient(to bottom right, transparent, #374151);
$gradient-green-1: linear-gradient(to bottom right, transparent, #14532d);
$gradient-red-1: linear-gradient(to bottom right, transparent, #5a151d);
$gradient-orange-1: linear-gradient(to bottom right, transparent, #fa7318);
$gradient-blue-1: linear-gradient(to bottom right, transparent, #254dbb);
$gradient-purple-1: linear-gradient(to bottom right, transparent, #711ba7);
$contentWidth: 1400px;
$headerHeight: 60px;
$pageTitleHeight: 70px;
$chapterTitleHeight: 40px;
$chapterPaddingBottom: 8px;
$editorTitleHeight: 20px;
$editorPartGap: 8px;

// **************************** variables ************************** END

html {
  overflow-y: scroll;
  overflow-x: hidden;
}

html,
body {
  font-family: $componentFontRegular;
  background-color: darken(#0f172a, 2%);
  color: white;
  padding: 0;
  margin: 0;
}

h1 {
  font-size: 36px;
  font-family: $componentFontBold;
}

.qc-hr {
  width: 100%;
  &::before {
    content: ' ';
    display: block;
    border-bottom: 3px solid $grey-1;
    padding-top: 3px;
  }
}

.w-100 {
  width: 100%;
}

// ************************ padding, margin ************************ START
// models
$pmModel: (
  padding: (
    prop: 'padding',
    class: 'p',
  ),
  margin: (
    prop: 'margin',
    class: 'm',
  ),
);
$trblModel: (
  top: (
    prop: 'top',
    class: 't',
  ),
  right: (
    prop: 'right',
    class: 'r',
  ),
  bottom: (
    prop: 'bottom',
    class: 'b',
  ),
  left: (
    prop: 'left',
    class: 'l',
  ),
);
// generate [padding/margin][top/right/bottom/left]-[0 to 9]
@mixin generateMarginPaddingTopRightBottomLeft($pm, $pmValues, $trbl, $trblValues, $i) {
  $value: $i/2 + rem;
  @if ($i == 1) {
    $value: $i/4 + rem;
  }
  .#{map-get($pmValues, class)}#{map-get($trblValues, class)}-#{$i} {
    #{map-get($pmValues, prop)}-#{map-get($trblValues, prop)}: $value !important;
  }
}
@each $pm, $pmValues in $pmModel {
  @each $trbl, $trblValues in $trblModel {
    @for $i from 0 to 10 {
      @include generateMarginPaddingTopRightBottomLeft($pm, $pmValues, $trbl, $trblValues, $i);
    }
  }
}
// generate [padding/margin]-[0 to 9]
@mixin generateMarginPadding($pm, $pmValues, $i) {
  $value: $i/2 + rem;
  @if ($i == 1) {
    $value: $i/4 + rem;
  }
  .#{map-get($pmValues, class)}-#{$i} {
    #{map-get($pmValues, prop)}: $value !important;
  }
}
@each $pm, $pmValues in $pmModel {
  @for $i from 0 to 10 {
    @include generateMarginPadding($pm, $pmValues, $i);
  }
}
// ************************ padding, margin ************************ END
// *************************** disaplay **************************** START
// models
$displayModel: inline, block, contents, flex, grid, inline-block, inline-flex, inline-grid, inline-table, list-item,
  run-in, table, table-caption, table-column-group, table-header-group, table-footer-group, table-row-group, table-cell,
  table-column, table-row, none, initial, inherit;
// generate
@mixin generateDisplay($display) {
  .d-#{$display} {
    display: $display !important;
  }
}
@each $display in $displayModel {
  @include generateDisplay($display);
}
// *************************** disaplay **************************** END
// ************************ flex-direction ************************* START
// models
$flexDirectionModel: row, row-reverse, column, column-reverse, initial, inherit;
// generate
@mixin generateFlexDirection($flexDirection) {
  .flex-#{$flexDirection} {
    flex-direction: $flexDirection !important;
  }
}
@each $flexDirection in $flexDirectionModel {
  @include generateFlexDirection($flexDirection);
}
// ************************ flex-direction ************************* END
// *********************** flex align-items ************************ START
// models
$flexAlignItemsModel: stretch, center, flex-start, flex-end, baseline, initial, inherit;
// generate
@mixin generateFlexAlignItems($flexAlignItems) {
  .align-items-#{$flexAlignItems} {
    align-items: $flexAlignItems !important;
  }
}
@each $flexAlignItems in $flexAlignItemsModel {
  @include generateFlexAlignItems($flexAlignItems);
}
// *********************** flex align-items ************************ END
// ********************* flex justify-content ********************** START
// models
$flexJustifyContentModel: flex-start, flex-end, center, space-between, space-around, space-evenly, initial, inherit;
// generate
@mixin generateFlexJustifyContent($flexJustifyContent) {
  .justify-content-#{$flexJustifyContent} {
    justify-content: $flexJustifyContent !important;
  }
}
@each $flexJustifyContent in $flexJustifyContentModel {
  @include generateFlexJustifyContent($flexJustifyContent);
}
// ********************* flex justify-content ********************** END
// ************************** flex wrap **************************** START
.flex-wrap {
  flex-wrap: wrap;
}
// ************************** flex wrap **************************** END

.qc-container {
  display: flex;
  justify-content: center;
  width: 100%;
  .qc-content {
    width: 100%;
    max-width: $contentWidth;
    margin: 0 16px;

    &__header {
      display: flex;
      align-items: center;
      justify-content: start;

      h1 {
        position: relative;
      }

      &-loader {
        position: absolute;
        right: -40px;
        top: 10px;
      }
    }
  }
}

.qc-contacts-info {
  display: flex;
  font-family: $componentFontRegular;
  font-size: 24px;
  gap: 6px;
}

.qc-nav {
  text-decoration: none;
}

//

.qc-loader-container {
  margin-left: 14px;
  margin-top: 4px;
  opacity: 1;

  &-enter {
    opacity: 1;
  }

  &-exit {
    opacity: 1;
    transition: opacity 0.3s ease;
  }

  &-exit-active {
    opacity: 0;
  }
}

//

.use-css-transition {
  opacity: 0;

  &-enter {
    opacity: 0;
  }

  &-enter-active {
    opacity: 1;
    transition: opacity 0.5s ease;
  }

  &-enter-done {
    opacity: 1;
  }
}

//

@media screen and (max-width: 1199px) {
  .qc-container {
    .qc-content {
      .qc-content__header {
        justify-content: center;
      }
    }
  }

  .qc-contacts-info {
    justify-content: center;
  }
}

@media screen and (max-width: 1199px) {
  .qc-container {
    .qc-content {
      width: 95%;
    }
  }
}

//

.qc-my-template-modal {
  .qc-modal__window {
    padding: 12px;
    background-color: $grey-1;
    flex-direction: column;

    .qc-my-template-modal__title {
      display: flex;
      font-size: 16px;
      font-family: $componentFontBold;
      padding: 10px 10px 16px 10px;

      &-name {
        color: $orange-1;
        margin: 0 6px;
      }
    }

    .qc-my-template-modal__buttons {
      display: flex;
      justify-content: center;
      gap: 12px;

      .qc-button {
        height: 40px;
      }
    }
  }
}

//

.qc-donate {
  p {
    margin: 0;
  }

  &-container {
    display: inline-flex;
    flex-direction: column;
  }

  &__link {
    color: rgb(34, 197, 94);
    margin-bottom: 6px;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

/**/

*::-webkit-scrollbar,
*::-webkit-scrollbar-thumb {
  width: 26px;
  border-radius: 13px;
  background-clip: padding-box;
  border: 10px solid transparent;
  color: $grey-2;
}

*::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 10px;
}

//

.qc__link {
  color: rgb(34, 197, 94);
  margin-bottom: 6px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
