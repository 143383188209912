@use '../../scss/qc.scss' as qc;

.root {
  display: flex;
  flex-direction: column;
  background-color: qc.$grey-1;
  width: 200px;
  padding: 6px 12px 12px 12px;
  gap: 10px;
}

.button {
  text-transform: none;
  height: 36px;
  justify-content: space-between;
  padding-right: 10px;

  img {
    height: 24px;
    width: 24px;
  }
}

.lang {
  font-family: qc.$componentFontBold;
  font-size: 16px;
  color: rgb(156, 163, 175);
}
