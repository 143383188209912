.root {
  --height: 36px;
  --fs: 14px;
  color: white;
  gap: 10px;
  margin-left: 8px;

  svg {
    font-size: 20px;
  }
}
