@import '../../scss/qc.scss';

$main-class: qc-data-list;

.#{$main-class} {
  display: flex;
  flex-direction: column;
  background-color: $grey-1;

  &:not(&-grid) {
    padding: 6px 10px;
    .#{$main-class}__item {
      width: 150px;
      margin: 4px 0;
      padding: 8px 12px;
      transition: all 0.2s ease-in;
      border-radius: 6px;

      &-selected:hover {
        background-color: $green-1 !important;
      }
    }
  }

  --item-fs: 14px;

  &__item {
    display: flex;
    align-items: center;
    font-family: $componentFontBold;
    font-size: var(--item-fs);
    color: white;
    background-color: $grey-1;
    user-select: none;
    cursor: pointer;
    transition: 0.15s all ease-in;

    &:not(&-selected):hover {
      background-color: $grey-2;
    }

    &-image {
      border-radius: 50%;
      background-color: $grey-3;
      width: 40px;
      height: 40px;
      margin: 4px 8px;
      padding: 6px;
    }

    &-description {
      letter-spacing: 0.08rem;
    }
  }

  &-grid {
    --item-fs: 15px;
    --col-count: 3;
    --gap: 5px;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: calc(
      var(--col-count) * (220px) + (var(--col-count) - 1) * var(--gap)
    );

    max-width: 100vw;
    gap: var(--gap);
    background-color: transparent;

    .#{$main-class}__item {
      width: 220px;
      padding: 0;
      background-image: $gradient-grey-1;

      &-description {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding-right: 12px;
        text-align: center;
      }
    }
  }

  &.#{$main-class}-green {
    .#{$main-class}__item {
      &-selected {
        background-color: $green-1;
        &:hover {
          background-color: $green-2;
        }
      }
    }
    &.#{$main-class}-grid {
      .#{$main-class}__item {
        &-selected {
          background-image: $gradient-green-1;
        }
      }
    }
  }

  &.#{$main-class}-red {
    .#{$main-class}__item {
      &-selected {
        background-color: $red-1;
        &:hover {
          background-color: $red-2;
        }
      }
    }
    &.#{$main-class}-grid {
      .#{$main-class}__item {
        &-selected {
          background-image: $gradient-red-1;
        }
      }
    }
  }

  &.#{$main-class}-transition-none {
    .#{$main-class}__item {
      transition: none !important;
    }
  }

  &.#{$main-class}-disabled {
    .#{$main-class}__item {
      cursor: not-allowed;
      background-color: $grey-1;
      color: rgba(white, 0.7);
      &:hover {
        background-color: none;
      }
      &-selected {
        background-color: rgba($green-1, 0.8) !important;
        &:hover {
          background-color: rgba($green-1, 0.8) !important;
        }
      }
    }
  }
}

@media screen and (max-width: 720px) {
  .#{$main-class} {
    &-grid {
      max-width: 450px;
    }
  }
}

@media screen and (max-width: 490px) {
  .#{$main-class} {
    &-grid {
      max-width: 220px;
    }
  }
}
