.root {
  $transition: (
    property: all,
    duration: 200ms,
    function: ease,
  );
  $background: (
    color: #fff,
    opacity: 0,
  );
  --opacity: 0;
  --blur: '0px';

  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 999;

  @if (var(--blur) != '0px') {
    backdrop-filter: blur(var(--blur));
  }
  @if (var(--opacity) != '0') {
    background-color: rgba(map-get($background, color), var(--opacity));
  }

  &.enter {
    opacity: 0;
  }

  &.enterActive {
    opacity: 1;
    transition: map-get($transition, duration) map-get($transition, function) map-get($transition, property);
  }

  &.exitActive {
    opacity: 0;
    transition: map-get($transition, duration) map-get($transition, function) map-get($transition, property);
  }
}
