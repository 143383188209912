@import './qc.scss';

$main-class: qc-create-session;

.#{$main-class} {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.#{$main-class}-container.qc-container {
  .qc-content {
    .qc-content__header {
      justify-content: flex-start;
    }
  }
}

@media screen and (max-width: 1099px) {
  .#{$main-class} {
    align-items: center;
  }

  .#{$main-class}-container.qc-container {
    .qc-content {
      .qc-content__header {
        justify-content: center;
      }
    }
  }
}
