@import '../../scss/qc.scss';

$main-class: qc-champion-algo;
.#{$main-class} {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 6px;
  border-radius: 6px;
  user-select: none;

  &__map {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    gap: 6px;
    border: 3px solid $grey-2;
    width: 230px;

    &-title {
      color: white;
      font-family: $componentFontBold;
      font-size: 14px;
      text-align: center;
      margin-top: 2px;
    }
  }
}
