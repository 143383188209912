@import '../../scss/qc.scss';
$main-class: qc-my-template;

.#{$main-class} {
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  color: white;
  border-radius: 0.25rem;
  background-color: $grey-1;
  background-image: $gradient-grey-1;

  &-container {
    padding: 12px 16px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .#{$main-class}-title {
      display: flex;
    }

    .#{$main-class}-buttons {
      display: flex;
    }
  }
}
