@import '../../scss/qc.scss';

$main-class: qc-text-edit;
$textEditStyleC: $green-2;

.#{$main-class} {
  --width: #{$componentWidth};
  --height: #{$componentHeight};
  --indent: 14px;
  --caption-fs: #{$captionFS};
  --caption-height: calc(var(--caption-fs) + 4px);
  --empty-caption-fs: #{$valueFS};
  --empty-caption-h: calc(var(--empty-caption-fs) + 4px);
  --value-fs: #{$valueFS};
  --icon-fs: #{$valueFS};

  display: inline-block;
  position: relative;
  width: var(--width);

  &__input {
    height: var(--height);
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $grey-1;
    background-image: $gradient-grey-1;
    transition: 0.3s all ease-in;
    border-radius: 5px 5px 0 0;

    &:hover {
      background-color: $grey-2;
    }

    &-container {
      display: flex;
      align-items: center;
      width: 100%;
      .#{$main-class}__caption {
        position: absolute;
        user-select: none;
        pointer-events: none;
        letter-spacing: 0.03rem;
        top: calc(0.6 * var(--indent));
        left: var(--indent);
        font-size: var(--caption-fs);
        color: $textEditStyleC;
        font-family: $componentFontBold;
        height: var(--caption-height);
        width: calc(var(--width) - 2 * var(--indent));
        overflow: hidden;
      }
      .#{$main-class}__value {
        font-size: var(--value-fs);
        font-family: $componentFontRegular;
        background: transparent;
        color: white;
        border: none;
        letter-spacing: 0.03rem;
        display: block;
        height: calc(var(--height) - var(--indent) - 0.4 * var(--indent));
        width: calc(100% - 2 * var(--indent));
        padding: calc(1.4 * var(--indent)) var(--indent) 0 var(--indent);
        border-radius: 5px 5px 0 0;
        &:focus {
          outline: none;
          background-color: $grey-2;
        }
      }
    }
  }

  &__bar {
    position: relative;
    display: block;
    &:before {
      content: '';
      height: 2px;
      width: 0;
      bottom: 0px;
      position: absolute;
      transition: 0.2s ease all;
      left: 0%;
      background: $textEditStyleC;
    }
  }

  &__buttons {
    padding-right: calc(0.7 * var(--indent));
    display: flex;
    .qc-button:hover {
      /*background-color: rgba(
        map-get(map-get($colors, grey), light-2),
        0.7
      ) !important;*/
    }
  }

  &-no-caption {
    .#{$main-class}__input {
      height: calc(var(--height) - var(--caption-height));
    }
    .#{$main-class}__value {
      padding-top: 0;
      padding-bottom: 0;
    }
    .#{$main-class}__caption {
      user-select: none;
    }
  }

  &-focused {
    .#{$main-class}__bar:before {
      width: 100%;
    }
  }

  &-empty {
    &:not(.#{$main-class}-no-caption):not(.#{$main-class}-focused)
      .#{$main-class}__caption {
      top: calc(0.5 * (var(--height) - var(--empty-caption-h)));
      height: var(--empty-caption-h);
      font-size: var(--empty-caption-fs);
    }
    &:not(.#{$main-class}-no-caption):not(.#{$main-class}-focused):not(.#{$main-class}-error)
      .#{$main-class}__caption {
      color: white;
    }
  }

  &-error {
    .#{$main-class}__caption {
      color: $red-3;
    }
    .#{$main-class}__bar:before {
      transition: none;
      width: 100%;
      background-color: rgb(255, 128, 128);
    }
  }
}
