@import '../../scss/qc.scss';

$main-class: qc-radio;

.#{$main-class}-container {
  --size: 20px;
  --fs: 14px;
  --indent: 14px;
  --caption-fs: #{$captionFS};

  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background-color: $grey-1;
  background-image: $gradient-grey-1;
  transition: 0.15s all ease-in;
  padding: 10px 14px 14px 14px;

  .#{$main-class}__caption {
    color: $green-2;
    user-select: none;
    pointer-events: none;
    letter-spacing: 0.03rem;
    font-size: 14px;
    font-family: $componentFontBold;
    overflow: hidden;
    height: 20px;
    display: block;
  }

  .#{$main-class} {
    cursor: pointer;
    display: inline-flex;
    margin-top: 10px;
    width: fit-content;

    &__circle {
      display: flex;
      position: relative;
      height: var(--size);
      width: var(--size);

      &-outer {
        display: block;
        position: absolute;
        box-sizing: border-box;
        top: 0;
        left: 0;
        width: var(--size);
        height: var(--size);
        border-width: 2px;
        border-style: solid;
        border-radius: 100%;
        transition: all 0.2s ease-out;
        border-color: $green-2;
      }

      &-inner {
        border-radius: 50%;
        box-sizing: border-box;
        display: block;
        position: absolute;
        height: var(--size);
        width: var(--size);
        left: 0;
        top: 0;
        opacity: 0;
        transition: transform ease 280ms, background-color ease 280ms,
          opacity linear 1ms 280ms;
        transform: scale(0.001);
        background-color: $green-2;
      }
    }

    &-checked {
      .#{$main-class}__circle-inner {
        transform: scale(0.5);
        opacity: 1;
        transition: transform ease 280ms, background-color ease 280ms;
      }
    }

    .#{$main-class}__description {
      display: flex;
      padding-left: var(--indent);
      font-size: 16px;
      font-family: $componentFontRegular;
      user-select: none;
    }

    &-disabled {
      cursor: not-allowed;
    }
  }

  &.#{$main-class}-transition-none {
    .#{$main-class}__outer-circle {
      transition: none !important;
    }
    .#{$main-class}__inner-circle {
      transition: none !important;
    }
  }
}
