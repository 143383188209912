@import '../../scss/qc.scss';

$main-class: qc-map-algo;
.#{$main-class} {
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-top: 6px;
  user-select: none;
}
