@import '../../scss/qc.scss';

$main-class: qc-loader;

.#{$main-class} {
  &.#{$main-class}-default {
    --speedMS: 1000ms;
    --thickness: 4px;
    --size: 16px;
    --color: #{$orange-1};
    --backColor: #{$grey-2};

    border: var(--thickness) solid var(--backColor);
    border-top: var(--thickness) solid var(--color);
    border-radius: 50%;
    width: var(--size);
    height: var(--size);
    animation: loader-1-animation var(--speedMS) linear infinite;
  }

  @keyframes loader-1-animation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
