.root {
  --top: 10px;
  --left: 0px;
  --translate: 10px;
  --transitionDelayMS: 200ms;

  position: relative;
  z-index: 1;
}

.container {
  position: absolute;
  border-radius: 5px;
  display: block;
  overflow-y: auto;
  top: var(--top);
  left: var(--left);
  overflow: hidden;
  z-index: 1000;

  &.fromBottom {
    &.enter {
      top: calc(var(--top) + var(--translate));
    }
    &.enterActive {
      transform: translateY(calc(-1 * var(--translate)));
    }
    &.exitActive {
      transform: translateY(var(--translate));
    }
  }

  &.fromTop {
    &.enter {
      top: calc(var(--top) - var(--translate));
    }
    &.enterActive {
      transform: translateY(var(--translate));
    }
    &.exitActive {
      transform: translateY(calc(-1 * var(--translate)));
    }
  }

  &.fromRight {
    &.enter {
      left: calc(var(--left) + var(--translate));
    }
    &.enterActive {
      transform: translateX(calc(-1 * var(--translate)));
    }
    &.exitActive {
      transform: translateX(var(--translate));
    }
  }

  &.fromLeft {
    &.enter {
      left: calc(var(--left) - var(--translate));
    }
    &.enterActive {
      transform: translateX(var(--translate));
    }
    &.exitActive {
      transform: translateX(calc(-1 * var(--translate)));
    }
  }

  &.fromBottom,
  &.fromTop,
  &.fromRight,
  &.fromLeft {
    padding: 0;

    &.enter {
      opacity: 0;
      &.disableOpenAnimation {
        opacity: 1;
      }
    }
    &.enterActive:not(.disableOpenAnimation) {
      opacity: 1;
      transition: var(--transitionDelayMS) ease all;
    }

    &.exitActive:not(.disableCloseAnimation) {
      opacity: 0;
      transition: var(--transitionDelayMS) ease all;
    }
    &.exit.disableCloseAnimation {
      display: none;
    }
  }
}
