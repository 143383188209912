@import './qc.scss';

$main-class: qc-sessions;

.#{$main-class} {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  gap: 12px;
}

@media screen and (max-width: 1431px) {
  .#{$main-class} {
    justify-content: center;
  }

  .#{$main-class}-container.qc-container {
    .qc-content {
      .qc-content__header {
        justify-content: center;
      }
    }
  }
}
