@import '../../scss/qc.scss';

$main-class: qc-pool-info-container;
.#{$main-class} {
  color: white;
  border-radius: 0.25rem;
  margin-bottom: 12px;
  border-width: 4px;
  border-style: solid;
  border-color: #222a36;
  user-select: none;

  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px 6px 0 6px;

    &-title {
      font-family: $componentFontBold;
      font-size: 20px;
      padding-bottom: 12px;

      &::first-letter {
        text-transform: uppercase;
      }
    }
  }
}
