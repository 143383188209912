@import '../../scss/qc.scss';

$main-class: qc-select-template;

.#{$main-class} {
  --width: #{$componentWidth};
  --height: #{$componentHeight};
  --indent: 14px;
  --caption-fs: #{$captionFS};
  --caption-height: calc(var(--caption-fs) + 4px);
  --empty-caption-fs: #{$valueFS};
  --empty-caption-h: calc(var(--empty-caption-fs) + 4px);
  --value-fs: #{$valueFS};
  --icon-fs: #{$valueFS};

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;

  width: var(--width);
  position: relative;
  z-index: 1;
  font-family: $componentFontRegular;
  display: inline-block;

  &.#{$main-class}-opened {
    z-index: 999;
    .#{$main-class}__arrow {
      transform: rotate(-180deg);
    }
    .#{$main-class}__input {
      background-color: $grey-2;
      background-image: none;
    }
  }

  &__input {
    height: var(--height);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px 5px 0 0;
    background-color: $grey-1;
    background-image: $gradient-grey-1;
    transition: 0.3s all ease-in;
    cursor: pointer;

    &:hover {
      background-color: $grey-2;
    }

    &-container {
      display: flex;
      align-items: center;
      width: 100%;
      .#{$main-class}__caption {
        position: absolute;
        user-select: none;
        pointer-events: none;
        letter-spacing: 0.03rem;
        top: calc(0.6 * var(--indent));
        left: var(--indent);
        font-size: var(--caption-fs);
        font-family: $componentFontBold;
        height: var(--caption-height);
        width: calc(var(--width) - 2 * var(--indent));
        overflow: hidden;
      }
      .#{$main-class}__value {
        user-select: none;
        font-size: var(--value-fs);
        font-family: 14px;
        background: transparent;
        color: white;
        border: none;
        letter-spacing: 0.03rem;
        display: flex;
        align-items: flex-end;
        height: calc(var(--height) - var(--indent) - 0.7 * var(--indent));
        width: calc(100% - 2 * var(--indent));
        padding: var(--indent) var(--indent) calc(0.7 * var(--indent))
          var(--indent);
        border-radius: 5px 5px 0 0;
        cursor: pointer;
      }
    }
    .#{$main-class}__icon {
      cursor: pointer;
      padding-right: var(--indent);
      font-size: var(--icon-fs);
      color: white;
      .#{$main-class}__arrow {
        transition: transform 0.2s ease-out;
      }
    }
  }

  &__bar {
    position: relative;
    display: block;
    &:before {
      content: '';
      height: 2px;
      width: 0;
      bottom: 0px;
      position: absolute;
      transition: 0.2s ease all;
      left: 0;
    }
  }

  &-no-caption {
    .#{$main-class}__input {
      height: calc(var(--height) - var(--caption-height));
    }
    .#{$main-class}__value {
      height: calc(var(--height) - var(--caption-height));
      padding-top: 0;
      padding-bottom: 0;
      align-items: center;
    }
  }

  &-empty {
    &:not(.#{$main-class}-no-caption) .#{$main-class}__caption {
      top: calc(0.5 * (var(--height) - var(--empty-caption-h)));
      height: var(--empty-caption-h);
      font-size: var(--empty-caption-fs);
    }
    &:not(.#{$main-class}-no-caption):not(.#{$main-class}-disabled):not(
        .#{$main-class}-error
      )
      .#{$main-class}__caption {
      color: white;
    }
  }

  &.#{$main-class}-opened .#{$main-class}__bar:before {
    width: 100%;
  }

  &.#{$main-class}-error {
    .#{$main-class}__input {
      .#{$main-class}__caption {
        color: $red-3;
      }
    }
    .#{$main-class}__bar:before {
      background-color: rgb(255, 128, 128);
    }
  }

  &.#{$main-class}-green {
    .#{$main-class}__input {
      .#{$main-class}__caption {
        color: $green-2;
      }
    }
    .#{$main-class}__bar:before {
      background-color: $green-2;
    }
  }

  &.#{$main-class}-red {
    .#{$main-class}__input {
      .#{$main-class}__caption {
        color: $red-3;
      }
    }
    .#{$main-class}__bar:before {
      background-color: $red-3;
    }
  }

  &__template,
  &__template-option {
    padding: 12px;

    &-title {
      font-family: $componentFontBold;
      font-size: 15px;
      letter-spacing: 0.04rem;
      color: $orange-1;
      user-select: none;
    }

    .qc-data-list {
      margin-top: 6px;
      width: auto;
      padding: 0;
      &__item {
        width: 220px !important;
        margin: 4px 0;
        padding: 8px 12px;
        transition: all 0.2s ease-in;
        border-radius: 6px;

        &-selected:hover {
          background-color: $green-1 !important;
        }
      }
    }
  }

  &-dropdown {
    display: flex;
    flex-direction: column;
    background-color: $grey-1;
  }

  &__split {
    min-width: 3px;
    background-color: $grey-2;
    margin: 15px 10px 12px 10px;
    border-radius: 16px;
  }

  &__preview {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 12px;
    user-select: none;

    &-desc {
      display: flex;
      font-family: $componentFontBold;
      font-size: 15px;
      background-color: $grey-2;
      color: white;
      padding: 8px 12px;
      border-left: 4px solid rgba(white, 0.6);
    }

    &-info {
      font-family: $componentFontBold;
      font-size: 15px;
      color: white;
      margin-left: 16px;
      margin-top: 4px;
    }

    &-image {
      border-radius: 50%;
      background-color: $grey-3;
      border: 3px solid $grey-2;
      width: 46px;
      height: 46px;
      margin: 3px;
      padding: 2px;
    }

    &-title {
      font-family: $componentFontBold;
      font-size: 18px;
      letter-spacing: 0.05rem;
      color: white;
    }

    &-maps-champions-methods {
      display: flex;
      flex-wrap: wrap;
      gap: 24px;
      width: 720px;
    }

    &-maps-and-champions {
      display: flex;
      flex-direction: column;
      width: 248px;
      gap: 12px;
    }

    &-maps,
    &-champions {
      display: flex;
      flex-direction: column;
      gap: 6px;
      &-list {
        display: flex;
        flex-wrap: wrap;
      }
    }

    &-game-type,
    &-first {
      display: flex;
      flex-direction: column;
      gap: 6px;
      &-info {
        display: flex;
        flex-wrap: wrap;
      }
    }

    &-methods-maps-and-champions {
      display: flex;
      flex-wrap: wrap;
      gap: 24px;
    }

    &-methods-maps,
    &-methods-champions {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      &-info {
        display: flex;
        flex-direction: column;
      }
    }
  }
}

@media screen and (max-width: 1361px) {
  .#{$main-class}__preview {
    &-maps-champions-methods {
      width: 512px;
    }

    &-methods-maps-and-champions,
    &-methods-maps,
    &-methods-champions {
      width: 240px;
    }
  }
}

@media screen and (max-width: 1180px) {
  .#{$main-class}__preview {
    &-maps-champions-methods {
      width: 256px;
    }

    &-methods-maps-and-champions,
    &-methods-maps,
    &-methods-champions {
      width: 248px;
    }
  }
}
