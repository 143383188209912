@import '../../scss/qc.scss';

$main-class: qc-modal;

.#{$main-class} {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(#000, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;

  &__window {
    display: flex;
    /*background-color: rgb(55, 65, 81);*/
    border-radius: 0.5rem;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 90vh;

    &-content {
      display: flex;
    }
  }

  &-enter {
    opacity: 0;
  }

  &-enter-active {
    opacity: 1;
    transition: 0.15s ease all;
  }

  &-exit-active {
    opacity: 0;
    transition: 0.15s ease all;
  }
}
