@import '../../scss/qc.scss';

$main-class: qc-alert;
.#{$main-class} {
  &__body {
    padding: 10px 10px 10px 0;
    font-family: $componentFontRegular;
    .#{$main-class}__title {
      font-family: $componentFontBold;
      font-size: 18px;
      margin-bottom: 0;
      margin-left: 16px;
    }
    .#{$main-class}__description {
      font-size: 14px;
      margin-bottom: 0;
      margin: 0 16px;
    }
  }

  &-red {
    color: white;
    background-color: #5a1d1d;
    border: 2px solid #be1100;
  }
}
