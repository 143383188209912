$main-class: qc-dropdown;

.#{$main-class} {
  --top: 10px;
  --left: 0px;
  --translate: 10px;
  --transition-delay-ms: 200ms;

  position: relative;
  z-index: 1;

  &.#{$main-class}-open {
    z-index: 999;
  }

  &-container {
    position: absolute;
    border-radius: 5px;
    display: block;
    overflow-y: auto;
    top: var(--top);
    left: var(--left);
    overflow: hidden;

    &-from-bottom {
      &-enter {
        top: calc(var(--top) + var(--translate));
      }
      &-enter-active {
        transform: translateY(calc(-1 * var(--translate)));
      }
      &-exit-active {
        transform: translateY(var(--translate));
      }
    }

    &-from-top {
      &-enter {
        top: calc(var(--top) - var(--translate));
      }
      &-enter-active {
        transform: translateY(var(--translate));
      }
      &-exit-active {
        transform: translateY(calc(-1 * var(--translate)));
      }
    }

    &-from-right {
      &-enter {
        left: calc(var(--left) + var(--translate));
      }
      &-enter-active {
        transform: translateX(calc(-1 * var(--translate)));
      }
      &-exit-active {
        transform: translateX(var(--translate));
      }
    }

    &-from-left {
      &-enter {
        left: calc(var(--left) - var(--translate));
      }
      &-enter-active {
        transform: translateX(var(--translate));
      }
      &-exit-active {
        transform: translateX(calc(-1 * var(--translate)));
      }
    }

    &-from-bottom,
    &-from-top,
    &-from-right,
    &-from-left {
      &-enter {
        opacity: 0;
        &.disable-open-animation {
          opacity: 1;
        }
      }
      &-enter-active:not(.disable-open-animation) {
        opacity: 1;
        transition: var(--transition-delay-ms) ease all;
      }
      &-exit-active:not(.disable-close-animation) {
        opacity: 0;
        transition: var(--transition-delay-ms) ease all;
      }
      &-exit.disable-close-animation {
        display: none;
      }
    }
  }

  &__backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: transparent;
    display: block;
  }
}
