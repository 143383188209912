@import './qc.scss';

$main-class: qc-session;

.#{$main-class} {
  width: 100%;
  &__header {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #232b37;
    width: 100%;
    padding: 0;
    margin: 0;
    border-bottom: 2px solid $grey-2;

    &-container {
      width: $contentWidth;
      padding: 8px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 20px 20px;
      padding: 8px 16px;

      .#{$main-class}__header-info {
        display: flex;
        flex-direction: column;

        &-players {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          font-family: $componentFontBold;
          font-size: 24px;

          &-vs {
            margin-left: 8px;
            margin-right: 8px;
            color: rgb(156, 163, 175);
          }
        }

        &-game-type {
          font-family: $componentFontBold;
          font-size: 16px;
          color: rgb(156, 163, 175);
          margin-top: 6px;
        }
      }

      .#{$main-class}__header-first {
        display: flex;
        flex-direction: column;

        &-desc {
          font-family: $componentFontBold;
          font-size: 16px;
          color: rgb(156, 163, 175);
        }

        &-value {
          font-family: $componentFontBold;
          font-size: 14px;
        }
      }

      .#{$main-class}__header-page-for {
        font-family: $componentFontBold;
        font-size: 16px;
        color: rgb(156, 163, 175);

        &-player {
          font-size: 18px;
          color: $red-3;
          margin-left: 6px;
        }
      }

      .#{$main-class}__header-links {
        display: flex;
        font-family: $componentFontBold;
        font-size: 18px;
      }
    }
  }

  &__body {
    display: flex;
    justify-content: center;
    &-container {
      width: $contentWidth;
      display: flex;
      justify-content: space-between;
      gap: 12px;
      margin: 0 16px;

      .maps {
        width: 100%;
        max-width: 430px;
      }

      .champions {
        width: 100%;
        max-width: 480px;
      }

      .champions-for-all-maps {
        width: 100%;
        max-width: 460px;
      }

      .results,
      .pools {
        max-width: 420px;
        min-width: 320px;
        width: auto;
      }
    }
  }

  opacity: 0;

  &-enter {
    opacity: 0;
  }

  &-enter-active {
    opacity: 1;
    transition: opacity 0.5s ease;
  }

  &-enter-done {
    opacity: 1;
  }

  @media screen and (min-width: 1200px) and (max-width: 1399px) {
    &__body {
      &-container {
        width: 100%;

        .maps {
          width: calc((100% - 444px) / 2);
        }

        .qc-pick-ban-info-map {
          width: 100%;
        }

        .champions {
          width: calc((100% - 444px) / 2);
        }

        .qc-pick-ban-info-for-map {
          width: calc(100% - 8px);

          .qc-pick-ban-info-champion {
            width: 100%;
          }
        }

        .champions-for-all-maps {
          width: calc((100% - 444px) / 2);
        }
      }
    }
  }

  @media screen and (max-width: 1299px) {
    &__header {
      &-container {
        flex-direction: column;

        .#{$main-class}__header-info,
        .#{$main-class}__header-first {
          align-items: center;
        }
      }
    }

    .qc-session__body {
      &-container {
        flex-direction: column;
        align-items: center;
        width: 95%;

        .champions {
          max-width: 430px;
        }

        .results,
        .pools {
          width: 100%;
        }

        .maps h1,
        .champions h1,
        .results h1,
        .pools h1 {
          text-align: center;
        }
      }
    }
  }
}
