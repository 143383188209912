@import './qc.scss';

$main-class: qc-my-template-edit;

.#{$main-class} {
  display: flex;
  gap: 18px;

  &__split {
    width: 3px;
    background-color: $grey-2;
    border-radius: 16px;
  }

  .qc-data-list-default-template-option {
    .qc-data-list__item {
      width: 236px;
    }
  }
}
