@use '../../scss/qc.scss' as qc;

@keyframes btn-click-effect {
  0% {
    transform: scale(0, 0);
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: scale(45, 45);
  }
}

.root {
  --height: 50px;
  --fs: 14px;
  --indent: 16px;
  --click-effect-size: 4px;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;

  height: var(--height);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-family: qc.$componentFontBold;
  font-size: var(--fs);
  letter-spacing: 0.03em;
  text-transform: uppercase;
  padding: 0 var(--indent);
  line-height: 1.75;
  border-radius: 4px;
  border: 0;
  user-select: none;
  transition: background-color 250ms ease-out;
  position: relative;
  overflow: hidden;
  color: white;
  cursor: pointer;

  &:focus {
    outline: 0;
  }

  &:disabled,
  .disabled {
    cursor: not-allowed;
    &:active {
      transform: none;
    }
  }

  &:hover {
    text-decoration: none;
  }

  &:active {
    transform: scale(0.96);
  }

  &::after {
    background: rgba(255, 255, 255, 0.4);
  }

  &:enabled:focus:not(:active)::after {
    animation: btn-click-effect 0.35s ease-out;
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: var(--click-effect-size);
    height: var(--click-effect-size);
    opacity: 0;
    border-radius: 100%;
    transform: scale(1, 1) translate(-50%);
    transform-origin: 50% 50%;
  }
}

.green {
  color: white;
  background-color: qc.$green-1;
  background-image: qc.$gradient-green-1;
  &:enabled:hover {
    background-color: qc.$green-2;
  }
  &.disabled {
    color: rgba(white, 0.5);
    background-color: rgba(#14532d, 0.5);
    background-image: none;
  }
}

.red {
  color: white;
  background-color: qc.$red-1;
  background-image: qc.$gradient-red-1;

  &:enabled:hover {
    background-color: qc.$red-2;
  }
}

.orange {
  color: black;
  background-color: qc.$orange-1;
  background-image: qc.$gradient-orange-1;

  &:enabled:hover {
    background-color: qc.$orange-2;
  }
}

.blue {
  color: white;
  background-color: qc.$blue-1;
  background-image: qc.$gradient-blue-1;

  &:enabled:hover {
    background-color: qc.$blue-2;
  }

  &.disabled {
    color: rgba(white, 0.5);
    background-color: rgba(#1663aa, 0.6);
    background-image: none;
  }
}

.indigo {
  background-color: rgb(87, 101, 242);
  $gradient-indigo-1: linear-gradient(to bottom right, transparent, darken(rgb(87, 101, 242), 5%));
  background-image: $gradient-indigo-1;

  &:enabled:hover {
    background-color: lighten(rgb(87, 101, 242), 7%);
  }
}

.purple {
  color: white;
  background-color: qc.$purple-1;
  background-image: qc.$gradient-purple-1;

  &:enabled:hover {
    background-color: qc.$purple-2;
  }
}
