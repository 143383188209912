@import '../../scss/qc.scss';

$main-class: qc-number-pick-ban;
.#{$main-class} {
  display: flex;
  font-family: $componentFontBold;
  font-size: 16px;
  height: 26px;
  background-color: $grey-2;
  border-radius: 6px;
  font-size: 14px;

  &-map {
    width: 180px;
  }

  &-champion {
    width: 217px;
  }

  &__number {
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 3px solid $grey-1;
    width: 30px;
  }

  &__info {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    margin-left: 10px;

    &-player,
    &-does,
    &-what {
      display: flex;
    }
  }

  &-green {
    .#{$main-class}__info-does {
      color: $green-2;
    }
  }

  &-red {
    .#{$main-class}__info-does {
      color: $red-3;
    }
  }

  &-orange {
    .#{$main-class}__info-does {
      color: $orange-1;
    }
  }
}
