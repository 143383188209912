@import '../../../scss/qc.scss';

.navbar {
  display: flex;
  justify-content: center;
  min-height: 54px;

  .navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: $contentWidth;
    margin: 0 16px;

    .title {
      display: flex;
      align-items: flex-end;
      height: 48px;
      font-family: $quake;
      font-size: 32px;
      letter-spacing: 0.3rem;
      color: white;
    }

    nav {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      font-family: $componentFontBold;
      letter-spacing: 0.08rem;
      a {
        padding: 8px 8px;
        color: white;
        font-size: 16px;
        text-decoration: none;
        &:hover {
          color: $green-2;
        }
      }
    }
  }

  @media screen and (min-width: 820px) and (max-width: 1400px) {
    .navbar-container {
      width: 100%;
    }
  }

  @media screen and (max-width: 819px) {
    .navbar-container {
      width: 100%;
      padding: 0;
      margin: 0;
      flex-direction: column;
      height: auto;

      nav {
        justify-content: center;
      }
    }
  }
}
