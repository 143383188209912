@import '../../scss/qc.scss';

$main-class: qc-template-options-editor;

.#{$main-class} {
  border-radius: 5px;
  background-color: $grey-1;
  background-image: $gradient-grey-1;
  transition: 0.15s all ease-in;

  &__option-container {
    padding: 8px;

    .qc-data-list {
      width: auto;
      background-color: transparent !important;
      padding: 0 6px;

      &__item {
        margin: 4px 0;
        padding: 12px 16px;
        transition: all 0.2s ease-in;
        border-radius: 6px;
        background-color: $grey-2;
        width: 226px !important;
      }
    }
  }

  &__caption {
    user-select: none;
    pointer-events: none;
    letter-spacing: 0.03rem;
    font-size: 14px;
    font-family: $componentFontBold;
    overflow: hidden;
    padding: 0 14px;
    margin-top: 12px;
    height: 20px;
    display: block;
  }

  &__buttons {
    display: flex;
    justify-content: center;
    padding: 4px 0 12px 0;
    gap: 16px;

    .qc-button {
      display: flex;
      border-radius: 50%;
      height: 40px;
      width: 40px;
      background-image: none;
      background-color: $grey-2;

      &:enabled:focus:not(:active)::after {
        animation: none;
      }

      &:hover {
        background-color: #657285 !important;
      }
    }
  }

  &.#{$main-class}-green {
    .#{$main-class}__caption {
      color: $green-2;
    }
  }

  &-disabled {
    cursor: not-allowed;
    background-color: #232d3b;
    .#{$main-class}__caption {
      color: rgba($green-2, 0.8) !important;
    }
    .qc-button {
      background-color: $grey-1;
      color: rgba(white, 0.7);
      &:hover {
        background-color: $grey-1 !important;
      }
    }
  }

  &.#{$main-class}-transition-none {
    transition: none !important;
  }

  &:not(.#{$main-class}-disabled) {
    .qc-data-list {
      &__item {
        &:hover {
          background-color: #657285;
        }
        &-selected {
          background-color: $green-1 !important;
        }
      }
    }
  }
}
