@import '../../scss/qc.scss';

$main-class: qc-pick-ban-info;
$fs: 16px;
$mr: 5px;

.#{$main-class} {
  width: 100%;
  color: white;
  border-radius: 0.25rem;
  margin-bottom: 12px;
  background-color: $grey-1;
  background-image: $gradient-grey-1;
  letter-spacing: 0.05rem;
  user-select: none;

  &__body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    font-family: $componentFontRegular;

    .#{$main-class}__who {
      font-family: $componentFontBold;
      font-size: $fs;
      margin-right: $mr;
    }
    .#{$main-class}__does {
      font-family: $componentFontBold;
      font-size: $fs;
      margin-right: $mr;
    }
    .#{$main-class}__target {
      font-family: $componentFontBold;
      font-size: $fs;
    }
    .#{$main-class}__waiting {
      font-family: $componentFontBold;
      font-size: $fs;
      margin-right: $mr;
      color: $orange-1;
    }
  }

  &-ban {
    .#{$main-class}__does {
      color: $red-3;
    }
  }

  &-pick {
    .#{$main-class}__does {
      color: $green-2;
    }
  }

  &-won {
    .#{$main-class}__does {
      color: $orange-1;
    }
  }

  &-waiting {
    .#{$main-class}__does {
      color: #{$orange-1};
    }
  }

  &__loader {
    margin: 0 12px;
  }
}
