$openSansRegular: openSansRegular;
$openSansBold: openSansBold;

@font-face {
  font-family: $openSansRegular;
  src: url('../fonts/OpenSans/OpenSans-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: $openSansBold;
  src: url('../fonts/OpenSans/OpenSans-Bold.ttf');
  font-weight: 600;
}

$robotoRegular: robotoRegular;
$robotoBold: robotoBold;

@font-face {
  font-family: $robotoRegular;
  src: url('../fonts/Roboto/Roboto-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: $robotoBold;
  src: url('../fonts/Roboto/Roboto-Bold.ttf');
  font-weight: 600;
}

$quake: quake;

@font-face {
  font-family: $quake;
  src: url('../fonts/Quake/dpquake_.ttf');
}
