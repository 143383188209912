@import '../../scss/qc.scss';

$main-class: qc-pool-info;
$fs: 16px;

.#{$main-class} {
  width: 280px;
  color: white;
  border-radius: 0.25rem;
  margin-bottom: 8px;
  letter-spacing: 0.05rem;
  user-select: none;

  background-color: $grey-1;
  background-image: $gradient-grey-1;

  &-aviable {
    width: 285px;
  }

  &-picked {
    border-left: 5px solid $green-1;
  }

  &-banned {
    border-left: 5px solid $red-1;
  }

  &-won {
    border-left: 5px solid $orange-1;
  }

  &__body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px;
    font-family: $componentFontRegular;

    &-what {
      font-family: $componentFontBold;
      font-size: $fs;
      margin-left: 20px;
    }

    &-image {
      border-radius: 50%;
      background-color: $grey-3;
      width: 50px;
      height: 50px;
      margin: 4px;
      padding: 3px;
      user-select: none;
    }
  }
}
