@import '../../scss/qc.scss';

$main-class: qc-pick-ban-info-for-map;
.#{$main-class} {
  color: white;
  border-radius: 0.25rem;
  margin-bottom: 12px;
  border: 4px solid #222a36;
  user-select: none;

  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 6px;

    &-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 4px 12px;
      box-sizing: border-box;
      font-family: $componentFontBold;
      font-size: 20px;

      &-text {
        padding: 10px 0;
      }

      &-image {
        border-radius: 50%;
        background-color: #222a36;
        width: 56px;
        height: 56px;
        margin: 3px;
        padding: 4px;
        user-select: none;
      }
    }

    .qc-done-steps-info {
      width: 100%;
    }
  }
}
