@import '../../scss/qc.scss';

$main-class: qc-pick-ban-results-info;
.#{$main-class} {
  min-width: 240px;
  display: flex;
  flex-direction: column;
  color: white;
  border-radius: 0.25rem;
  margin-bottom: 12px;
  background-color: $grey-1;
  user-select: none;

  &__title {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-family: $componentFontBold;
    padding: 12px;

    &-players {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      flex-wrap: wrap;
      font-size: 20px;

      &-vs {
        margin-left: 6px;
        margin-right: 6px;
        color: rgb(156, 163, 175);
      }
    }

    &-game-type {
      font-size: 16px;
      color: rgb(156, 163, 175);
      margin-top: 6px;
    }
  }

  &__map {
    &-title {
      display: flex;
      align-items: center;

      &__body {
        text-align: center;
        font-family: $componentFontBold;
        background-color: #232b37;
        width: 100%;
        padding: 12px 0;

        &-map {
          font-size: 18px;
          margin-left: 12px;
        }

        &-by {
          font-size: 14px;
          color: rgb(156, 163, 175);
          margin-left: 6px;
        }

        &-picked-by {
          color: $orange-1;
          font-size: 14px;
          margin-right: 12px;
          margin-left: 6px;
        }
      }
    }

    &-champions {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      padding: 8px;

      &-image {
        border-radius: 50%;
        background-color: $grey-3;
        width: 60px;
        height: 60px;
        margin: 6px 12px;
        padding: 6px;
        user-select: none;

        &-ban {
          background-color: $red-1;
          background-image: $gradient-red-1;
        }

        &-pick {
          background-color: $green-1;
          background-image: $gradient-green-1;
        }
      }
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90px;
    background-color: #232b37;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
}
