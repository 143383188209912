@use '../../scss/qc.scss' as qc;

.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  outline: none;

  header {
    width: 100%;
    background: qc.$grey-1;
    border-bottom: 2px solid qc.$grey-2;
    box-sizing: border-box;
  }
  main {
    flex: 0 1 auto;
  }
}
