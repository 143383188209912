.root {
  --height: 42px;
  --fs: 14px;
  color: white;
  gap: 12px;
  margin-left: 8px;
  padding: 4px 12px 4px 8px;

  img {
    height: 36px;
    width: 36px;
    border-radius: 50%;
  }
}
