@import '../../scss/qc.scss';

$main-class: qc-button;

@keyframes btn-click-effect {
  0% {
    transform: scale(0, 0);
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: scale(45, 45);
  }
}

.#{$main-class} {
  --height: 50px;
  --fs: 14px;
  --indent: 16px;
  --click-effect-size: 4px;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;

  cursor: pointer;
  &:focus {
    outline: 0;
  }
  &:disabled {
    cursor: not-allowed;
    &:active {
      transform: none;
    }
  }
  &:hover {
    text-decoration: none;
  }

  &-default {
    height: var(--height);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    font-family: $componentFontBold;
    font-size: var(--fs);
    letter-spacing: 0.03em;
    text-transform: uppercase;
    padding: 0 var(--indent);
    line-height: 1.75;
    border-radius: 4px;
    border: 0;
    user-select: none;
    transition: background-color 250ms ease-out;
    position: relative;
    overflow: hidden;
    color: white;

    &:active {
      transform: scale(0.96);
    }

    &::after {
      background: rgba(255, 255, 255, 0.4);
    }

    &:enabled:focus:not(:active)::after {
      animation: btn-click-effect 0.35s ease-out;
    }

    &.#{$main-class}-green {
      color: white;
      background-color: $green-1;
      background-image: $gradient-green-1;
      &:enabled:hover {
        background-color: $green-2;
      }
      &.#{$main-class}-disabled {
        color: rgba(white, 0.5);
        background-color: rgba(#14532d, 0.5);
        background-image: none;
      }
    }

    &.#{$main-class}-red {
      color: white;
      background-color: $red-1;
      background-image: $gradient-red-1;
      &:enabled:hover {
        background-color: $red-2;
      }
    }

    &.#{$main-class}-orange {
      color: black;
      background-color: $orange-1;
      background-image: $gradient-orange-1;
      &:enabled:hover {
        background-color: $orange-2;
      }
    }

    &.#{$main-class}-blue {
      color: white;
      background-color: $blue-1;
      background-image: $gradient-blue-1;
      &:enabled:hover {
        background-color: $blue-2;
      }
      &.#{$main-class}-disabled {
        color: rgba(white, 0.5);
        background-color: rgba(#1663aa, 0.6);
        background-image: none;
      }
    }

    &.#{$main-class}-transition-none {
      transition: none !important;
    }

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: var(--click-effect-size);
      height: var(--click-effect-size);
      opacity: 0;
      border-radius: 100%;
      transform: scale(1, 1) translate(-50%);
      transform-origin: 50% 50%;
    }
  }

  &-shadow-none {
    box-shadow: none;
  }
}
