@import '../../scss/qc.scss';

$main-class: qc-session-info;
$infoWidth: 341px;

.qc-nav {
  width: 100%;
  max-width: $infoWidth;
}

.#{$main-class} {
  max-width: $infoWidth;
  color: white;
  border-radius: 0.25rem;
  background-color: $grey-1;
  background-image: $gradient-grey-1;
  cursor: pointer;

  &:hover {
    background-color: $grey-2;
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px;
    font-family: $componentFontRegular;

    .#{$main-class}-title {
      font-family: $componentFontBold;
      font-size: 14px;
    }

    .#{$main-class}-game-type {
      font-family: $componentFontBold;
      font-size: 14px;
      color: rgb(156, 163, 175);
    }

    .#{$main-class}-create-date {
      font-family: $componentFontBold;
      font-size: 14px;
      color: $green-2;
      margin-left: 12px;
    }

    .#{$main-class}-create-time {
      font-family: $componentFontBold;
      font-size: 14px;
      color: $orange-1;
      margin-left: 4px;
    }
  }
}
